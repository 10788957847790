import { Vue, Component, Prop } from 'vue-property-decorator';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import VideoPlayer from '@/commoncomponents/VideoPlayer/VideoPlayer.vue';

enum EMBEDDED_ASSET_TYPE {
  IMAGE = 'image',
  VIDEO = 'video'
}

@Component({
  name: 'cms-contentful-render',
  props: {
    document: { type: Object }
  },
  components: {
    'contentful-rich-text': RichTextRenderer
  }
})
export default class CmsTagComponent extends Vue {
  @Prop({
    type: Object
  })
  document = {};

  renderMarks() {
    return {
      [MARKS.BOLD]: (text: string, key: string, h: any) => {
        return h('strong', { key }, text);
      },

      [MARKS.ITALIC]: (text: string, key: string, h: any) => {
        return h('em', { key }, text);
      },

      [MARKS.UNDERLINE]: (text: string, key: string, h: any) => {
        return h('u', { key }, text);
      },

      [MARKS.CODE]: (text: string, key: string, h: any) => {
        return h('pre', { key }, text);
      },

      [MARKS.SUBSCRIPT]: (text: string, key: string, h: any) => {
        return h('sub', { key }, text);
      },

      [MARKS.SUPERSCRIPT]: (text: string, key: string, h: any) => {
        return h('sup', { key }, text);
      }
    };
  }

  renderNodes() {
    return {

      [BLOCKS.LIST_ITEM]: (node: any, key: string, h: any, next: any) => {
        return h('li', { key }, next(node.content, key, h, next));
      },

      [BLOCKS.PARAGRAPH]: (node: any, key: string, h: any, next: any) => {
        return h('p', { key }, next(node.content, key, h, next));
      },

      [BLOCKS.EMBEDDED_ASSET]: (node: any, key: any, h: any, next: any) => {
        const id = node.data.target.sys.id;
        const block = (this.document as any).links.assets.block;
        const linkData = block.find((item: any) => item.sys.id === id);

        const assetType = this.extractAssetType(linkData.contentType);

        const aspectRatio = linkData.width / linkData.height;
        const imgHeight = linkData.width > 450
          ? Math.round(450 / aspectRatio)
          : linkData.height;


        if (assetType) {
          switch (assetType) {
            case EMBEDDED_ASSET_TYPE.IMAGE:
              return h(
                'img',
                // @TODO: Right now aspect ratio is wonky for images inside the accordion.
                // It's that or have the wrong accordion height and cut off images.
                // To be revisited during UAT/CAT.
                // JIRA ISSUE: RFD-274
                {
                  key,
                  attrs: { src: linkData.url },
                  style: {
                    minHeight: imgHeight,
                    display: 'block',
                    aspectRatio,
                    ...(linkData.width > 450
                      ? { width: '100%' }
                      : {}
                    )
                  }
                },
                next(node.content, key, h, next)
              );
            case EMBEDDED_ASSET_TYPE.VIDEO:
              return h(
                VideoPlayer,
                { props: { videoUrl: linkData.url }, attrs: { class: 'video' } },
                next(node.content, key, h, next)
              );
          }
        }
        else {
          console.error('Embedded asset type not supported.');
        }
      }
    };
  }

  extractAssetType(contentType: string): string | null {
    const match = contentType.match(/^(image|video)\//);
    return match
      ? match[1]
      : null;
  }
}
