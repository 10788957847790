import { Vue, Component } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import { ScreenText } from '@/lang/ScreenText';
@Component({})
export default class MobileScreenUnavailable extends Vue {
    
    private objScreenText: ScreenText = new ScreenText();
    public localConst:any = APP_CONST;

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }
}