import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'cms-not-authorized',

})
export default class CmsNotAuthorizedComponent extends Vue {


}
