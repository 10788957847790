








import CmsNotAuthorizedComponent from './CmsNotAuthorizedComponent';
export default CmsNotAuthorizedComponent;
