import { Prop, Watch } from 'vue-property-decorator';

import CmsCardComponent from '@/commoncomponents/CmsCardComponent/CmsCardComponent.vue';
import BreadcrumbComponent from '@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue';
import MobileScreenUnavailable from '@/commoncomponents/mobileScreenUnavailable/MobileScreenUnavailable.vue';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import { Component, Mixins } from 'vue-property-decorator';
import CmsAccordion from '@/commoncomponents/CmsAccordion/CmsAccordion.vue';
import CmsCardCarousel from '@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue';
import CmsLabelComponent from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue';
import CmsTagComponent from '@/commoncomponents/CmsTagComponent/CmsTagComponent.vue';
import Checkmark from '../../../assets/images/cms/checkmark.svg';
import Bullet from '../../../assets/images/cms/link_bullet_blue.svg';
import ChevronRight from '../../../assets/images/cms/chevron_right_nav_link.svg';
import Printer from '../../../assets/images/cms/print.svg';

import axios from 'axios';
import {
  RoutinePageData,
  checkCMSEnabledReRoute,
  getCopyrightMessage,
  isAuthorizedContent,
  routineResponseToData
} from '@/utilities/cmsUtilities';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import APP_CONST from '@/constants/AppConst';
import CmsContentfulComponent from '@/commoncomponents/CmsContentfulComponent/CmsContentfulComponent.vue';
import CmsNotAuthorizedComponent from '@/commoncomponents/CmsNotAuthorizedComponent/CmsNotAuthorizedComponent.vue';
import { getContentfulGraphQLQuery, getRelatedContentfulGraphQLQuery } from '@/utilities/contentfulGraphQLHelpers';
import programListStore from '@/store/modules/programList';


@Component({
  components: {
    'bread-crumb': BreadcrumbComponent,
    'mobile-unavailable-screen': MobileScreenUnavailable,
    'cms-card': CmsCardComponent,
    'collapsible-layout-card': CmsAccordion,
    'cms-tag-list': CmsTagComponent,
    'cms-content-label': CmsLabelComponent,
    'card-carousel': CmsCardCarousel,
    'cms-contentful-render': CmsContentfulComponent,
    'bouncing-preloader': BouncingPreloaderComponent,
    'cms-not-authorized': CmsNotAuthorizedComponent
  }
})
export default class RoutineLandingPage extends Mixins(
  DeviceWidthCheckerMixin
) {
  //Images from assets folder
  checkmarkImage = Checkmark;
  resourceLinkImage = Bullet;
  navLinksChevron = ChevronRight;
  printIcon = Printer;

  routineId: string = '';
  @Prop()
  routineIdQuery: string | undefined;

  routineData: RoutinePageData = {
    title: '',
    id: '',
    heroImage: '',
    objectivesList: [],
    courseDescription: '',
    tagList: [],
    labelData: [],
    navLinks: [],
    resourceLinks: [],
    relatedContentCards: [],
    url: '',
    itemDescription: '',
    exploreContentfulObj: undefined,
    reflectContentfulObj: undefined,
    why: ''
  };
  loading: boolean = true;
  isAuthorized: boolean = true;

  isPrinting: boolean = false;


  /* istanbul ignore next */
  get lxEnabled() {
    return programListStore.appSetting;
  }
  @Watch('lxEnabled', { immediate: true, deep: true })
  cmsFeatureFlag(store: any) {
    if (checkCMSEnabledReRoute(store)) {
      this.$router.push({ path: '/' });
    }
  }

  async fetchData() {
    const url = `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`;
    const headers = { Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}` };
    const method = 'post';
    return axios({
      url: url,
      headers: headers,
      method: method,
      data: {
        query: getContentfulGraphQLQuery('routine', this.routineId)
      }
    }).then(routineResult => {
      const routinePageData = routineResult;
      // Fetch related content
      axios({
        url: url,
        headers: headers,
        method: method,
        data: {
          query: getRelatedContentfulGraphQLQuery('routine', this.routineId, true)
        }
      }).then((relatedResult) => {
        // Merge related content into routine data
        routinePageData.data.data.routine = {
          ...routinePageData.data.data.routine,
          ...relatedResult.data.data.routine
        };
        const structuredData = routineResponseToData(routinePageData);
        this.isAuthorized = isAuthorizedContent(structuredData);

        this.routineData = {
          ...structuredData
        };
        this.loading = false;
        return structuredData;
      }).catch(err => {
        console.error('Failed to Fetch Related Content', err);
      });
    }).catch(err => {
      console.error('Failed to Fetch Landing Page', err);
    });
  }

  async updatedId() {
    const id: string = this.$route.query.id as string || '';
    this.loading = true;
    this.routineId = id || '';
    this.fetchData();
  }

  openCourse() {
    window.open(this.routineData.url, '_blank');
  }

  beforeMount() {
    const id: string = this.$route.query.id as string || '';
    if (id) {
      this.routineId = id.toString();
    }
    this.$watch('$route', this.updatedId);
    // if(!this.courseData.courseId.length){
    this.fetchData();
    // }
  }
  getCopyrightMessage = getCopyrightMessage;

  created() {
    window.addEventListener('beforeprint', event => {
      console.log('before print', event);
      this.isPrinting = true;
    });
    window.addEventListener('afterprint', event => {
      console.log('after print', event);
      this.isPrinting = false;
      this.$forceUpdate();
    });
  }

  handlePrint() {
    this.isPrinting = true;
    this.$forceUpdate();
    this.$nextTick(() => {
      setTimeout(() => {
        print();
        this.$forceUpdate();
      }, 1000);
    });
  }
}
