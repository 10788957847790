import { render, staticRenderFns } from "./CmsNotAuthorizedComponent.vue?vue&type=template&id=06e3eda0&scoped=true&"
import script from "./CmsNotAuthorizedComponent.vue?vue&type=script&lang=ts&"
export * from "./CmsNotAuthorizedComponent.vue?vue&type=script&lang=ts&"
import style0 from "./CmsNotAuthorizedComponent.less?vue&type=style&index=0&id=06e3eda0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e3eda0",
  null
  
)

export default component.exports