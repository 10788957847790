import { Vue, Component, Prop } from 'vue-property-decorator';
import ManWoman from '../../assets/images/cms/man_woman.svg';
import ChevronOpen from '../../assets/images/cms/chevron_open.svg';
import ChevronClosed from '../../assets/images/cms/chevron_closed.svg';
import APP_CONST from '@/constants/AppConst';

export type CollapseData = {
  title: string;
  subTitle?: string;
  icon?: string;
  description?: string;
  hideChevron?: boolean;
  backgroundColor?: string;
};

@Component({
  name: 'collapsible-layout-card',
  props: {
    cardData: {
      type: Object
    }
  }
})
export default class CmsAccordion extends Vue {
  private propOpen: boolean | undefined = undefined;
  readonly STYLE = APP_CONST.STYLE;

  @Prop({
    required: false,
    default: () => true
  })
  isOpen!: boolean;

  chevronIcon!: string;

  hideChevron: boolean = false;

  @Prop({
    required: true,
    default: () => ({
      title: 'Title',
      subTitle: '(10 min)',
      icon: ManWoman,
      description:
        'View the resources associated with this course.',
      hideChevron: false
    })
  })
  accordionData!: CollapseData;

  contentHeight: string = 'unset';

  handleResize() {
    this.contentHeight = 'unset';
    this.$nextTick(() => {
      const content = this.$el.getElementsByClassName('collapse-container')[0];
      if (content) {
        if (this.contentHeight === 'unset') {
          this.contentHeight = `${content.clientHeight}px`;
          if (this.propOpen !== undefined) {
            this.isOpen = this.propOpen;
          }
        }
      }
    });
  }

  mounted() {
    this.$nextTick(() => {
      /**
       * Since the height of the child content is variable, css has trouble animating the height of the parent.
       * This function captures the height of the child when the component first mounts.
       * */
      this.handleResize();
    });
  }

  beforeMount() {
    this.chevronIcon = this.isOpen
      ? ChevronOpen
      : ChevronClosed;
    if (this.accordionData.hideChevron === true) {
      this.hideChevron = true;
      this.isOpen = true;
    }
    this.propOpen = this.isOpen;
    this.isOpen = true;
  }



  created() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('beforeprint', (event) => {
      this.handleResize();
    });
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleClick() {
    if (!this.hideChevron) {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.chevronIcon = ChevronOpen;
      }
      else {
        this.chevronIcon = ChevronClosed;
      }
    }
    console.log(this.isOpen);
  }
}
